import { forwardRef } from "react";

export const AudioWaveTimeline = forwardRef(
  ({ color, className, width, height, ...props }, ref) => {
    const paths = [
      "M1.99988 27.7776V48.7749",
      "M11.1132 27.7776V48.7749",
      "M20.2266 19.2927V57.2593",
      "M29.34 9.08472V67.4772",
      "M38.4534 22.5972V53.9551",
      "M47.5668 27.7776V48.7749",
      "M56.6714 27.7776V48.7749",
      "M65.7825 19.2927V57.2593",
      "M74.8959 9.08472V67.4772",
      "M83.7166 27.7776V48.7749",
      "M93 20.9595V55.5931",
      "M102 27.7776V48.7749",
      "M111 59.2212V17.3408",
      "M120 2V74.5621",
      "M129 27.7776V48.7749",
      "M138 20.9595V55.5931",
      "M147 27.7776V48.7749",
      "M156 27.7776V48.7749",
      "M165 19.2927V57.2593",
      "M174 9.08447V67.477",
      "M183 27.7776V48.7749",
      "M192 6.42773V70.1243",
      "M201 14.5696V61.9826",
      "M210 28V48.9973",
      "M219 27.7776V48.7749",
      "M228 22.5972V53.9551",
      "M237 13.5034V63.0494",
      "M246 17.3408V59.2212",
      "M255 9.08447V67.477",
      "M264 20.9595V55.5931",
      "M273 17.3408V59.2212",
      "M282 20.9595V55.5931",
      "M291 27.7776V48.7749",
      "M300 27.3594V48.3567",
    ];

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={232}
        height={57}
        ref={ref}
        viewBox="0 0 302 77"
        {...props}
        fill="none"
      >
        {paths.map((d, index) => (
          <path
            key={index}
            d={d}
            stroke={color}
            strokeWidth="3"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
        ))}
        <style>{`
        .tc-fill {
          stroke: #1b3d32;
        }
      `}</style>
      </svg>
    );
  }
);

AudioWaveTimeline.displayName = "AudioWaveTimeline";
