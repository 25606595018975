import React, { useState, useRef, useEffect } from "react";
import { AudioWaveTimeline } from "./AudioWave";
import PropTypes from "prop-types";

const AudioStrip = ({ data }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);
  const timelineRef = useRef(null);
  useEffect(() => {
    const timelineRefs = timelineRef.current.childNodes;
    const waveLinesCount = timelineRefs.length;
    if (audioRef.current) {
      const audioElement = audioRef.current;

      const changePathColor = () => {
        const playPercent =
          (audioElement.currentTime / audioElement.duration) * 100;
        const currentPath =
          timelineRefs[Math.floor((playPercent / 100) * waveLinesCount)];
        if (currentPath) {
          currentPath.classList.add("tc-fill");
          const nextSibling = currentPath.nextElementSibling;
          const prevSibling = currentPath.previousElementSibling;
          if (prevSibling) prevSibling.classList.add("tc-fill");
          if (nextSibling) nextSibling.classList.add("tc-fill");
        }
      };

      audioElement.addEventListener("timeupdate", changePathColor);

      return () => {
        audioElement.removeEventListener("timeupdate", changePathColor);
      };
    }
  }, []);

  const handleAudioEnd = () => {
    const timelineRefs = timelineRef.current.childNodes;
    timelineRefs.forEach((path) => path.classList.remove("tc-fill"));
    setIsPlaying(false);
  };

  const handlePlayPause = () => {
    if (audioRef.current) {
      isPlaying ? audioRef.current.pause() : audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  return (
    <div className="fx mb-5 audio-strip">
      <button
        className="audio-btn"
        onClick={handlePlayPause}
        data-testid="audio-element"
      >
        <svg width="32" height="32">
          <use
            href={`/sprite.svg#audio-${isPlaying ? "pause" : "play"}-icon`}
          />
        </svg>
      </button>
      <audio ref={audioRef} src={data.src} onEnded={handleAudioEnd} />
      <AudioWaveTimeline color={"white"} ref={timelineRef} />
    </div>
  );
};

AudioStrip.propTypes = {
  data: PropTypes.shape({
    src: PropTypes.string.isRequired,
  }).isRequired,
};
export default AudioStrip;
